import React from 'react'
import useFetch from '~/hooks/useFetch'
import SearchCarousel from '~/components/SearchCarousel'
import SearchGrid from '~/components/SearchGrid'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as playerActions from '~/ducks/player'
import SearchMediaGrid from '~/components/SearchMediaGrid'
import PlayAllButton from './PlayAllButton'
import { useDispatch } from '~/reducers'
import { useTranslation } from '~/i18n'

const useStyles = makeStyles(() => ({
  withMarginBottom: {
    marginBottom: 50,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gutterTitle: {
    marginBottom: 20,
  },
}))

function useSearchPagination(url: string) {
  const { data, isValidating, fetcher, mutate } = useFetch(url, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  })

  React.useEffect(() => {
    if (!data) {
      mutate()
    }
  }, [mutate, data])

  const loadMore = async () => {
    const newData = await fetcher(data.next)
    return mutate(
      {
        ...newData,
        results: [...data.results, ...newData.results],
      },
      false
    )
  }
  return { data, loadMore, loading: isValidating }
}

interface Props {
  playlistTitle?: string | null
  title: React.ReactNode
  subtitle?: React.ReactNode
  grid?: boolean
  after?: any
  className?: string
  params: string
  mediaGrid?: boolean
  handlePlay?: () => void
  playable?: { title: string; objectId: string }
  gridProps?: any
  carouselProps?: any
}

const SimpleSearch = ({
  params,
  playlistTitle,
  title,
  subtitle,
  grid,
  after,
  className,
  mediaGrid,
  playable,
  gridProps,
  carouselProps,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pageSize = grid ? 12 : 13
  const { t } = useTranslation('in_page_search_results')
  const { data, loading, loadMore } = useSearchPagination(
    `/api/v1/search/?page_size=${pageSize}&${params}`
  )

  const extractPlaylist = ({ results, post }: { results: any[]; post: any }) =>
    results
      .filter((d) => d.global_id !== post.global_id)
      .map((d) => d.global_id)

  if (data?.results && data.results.length > 0) {
    let ResultsComponent
    if (grid) {
      ResultsComponent = (props: any) => (
        <SearchGrid {...gridProps} {...props} />
      )
    } else if (mediaGrid) {
      ResultsComponent = SearchMediaGrid
    } else {
      ResultsComponent = (props: any) => (
        <SearchCarousel {...props} carouselProps={carouselProps} />
      )
    }
    return (
      <div className={className}>
        <div className={classes.withMarginBottom}>
          <div
            className={clsx(classes.title, {
              [classes.gutterTitle]: !!grid || !!mediaGrid,
            })}
          >
            {title}
            {playable && (
              <PlayAllButton
                title={playable.title}
                objectId={playable.objectId}
              />
            )}
          </div>
          {subtitle && <div>{subtitle}</div>}
          <ResultsComponent
            results={data.results}
            onPlayClick={(node: any) => {
              dispatch(playerActions.play({ objectId: node.global_id }))
              dispatch(
                playerActions.setPlaylist({
                  playlist: extractPlaylist({
                    post: node,
                    results: data.results,
                  }),
                  title: playlistTitle || 'Recherche sans titre',
                })
              )
            }}
          />
          {(grid || mediaGrid) && data.next && (
            <div style={{ textAlign: 'center', paddingTop: 40 }}>
              <Button
                onClick={() => loadMore()}
                variant="contained"
                size="large"
                disabled={loading}
              >
                {t('Afficher plus')}
              </Button>
            </div>
          )}
        </div>
        {loading && <LinearProgress variant="indeterminate" />}
        {after}
      </div>
    )
  } else {
    return null
  }
}

export default SimpleSearch
