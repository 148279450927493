import React from 'react'
import Carousel from 'nuka-carousel'
import { withStyles } from '@material-ui/core/styles'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import IconButton from '@material-ui/core/IconButton'
import ContainerDimensions from 'react-container-dimensions'
import compose from 'recompose/compose'
import { keys as breakpointKeys } from '@material-ui/core/styles/createBreakpoints'

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: [[0, 'auto']],
    paddingTop: 48,
    position: 'relative',
  },
  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

const RESPONSIVE_ITEMS_PER_ROW = {
  xs: 2,
  sm: 4,
  md: 5,
  lg: 5,
  xl: 6,
}

class CustomCarousel extends React.Component {
  state = {
    slideIndex: 0,
  }

  resize = (ref) => {
    ref = ref || this.ref
    ref && ref.setDimensions()
  }

  /**
   *  From https://github.com/mui-org/material-ui/blob/60a3549d2c5bf68f7411c65a3b819b98dc6f2de0/packages/material-ui/src/withWidth/withWidth.js#L70-L96
   * @param {*} innerWidth
   */
  getWidth(innerWidth = window.innerWidth) {
    const breakpoints = this.props.theme.breakpoints
    let width = null
    /**
     * Start with the slowest value as low end devices often have a small screen.
     *
     * innerWidth |xs      sm      md      lg      xl
     *            |-------|-------|-------|-------|------>
     * width      |  xs   |  sm   |  md   |  lg   |  xl
     */
    let index = 1
    while (width === null && index < breakpointKeys.length) {
      const currentWidth = breakpointKeys[index]
      // @media are inclusive, so reproduce the behavior here.
      if (innerWidth < breakpoints.values[currentWidth]) {
        width = breakpointKeys[index - 1]
        break
      }
      index += 1
    }
    width = width || 'xl'
    return width
  }

  renderCarousel = ({ width, slidesToShow }) => {
    const { classes, className, controlsClassName, ...props } = this.props
    const { slideIndex } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.controls}>
          <IconButton
            disabled={slideIndex <= 0}
            onClick={() =>
              this.setState({
                slideIndex: Math.max(
                  0,
                  slideIndex - this.ref.state.slidesToScroll
                ),
              })
            }
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            disabled={
              this.ref &&
              slideIndex + this.ref.state.slidesToScroll >=
                this.ref.state.slideCount
            }
            onClick={() =>
              this.setState({
                slideIndex: slideIndex + this.ref.state.slidesToScroll,
              })
            }
          >
            <ArrowRight />
          </IconButton>
        </div>
        <Carousel
          width={`${width}px`}
          speed={500}
          cellSpacing={30}
          slidesToScroll={slidesToShow}
          ref={(ref) => (this.ref = ref)}
          className={className}
          slideIndex={this.state.slideIndex}
          afterSlide={(slideIndex) => this.setState({ slideIndex })}
          withoutControls
          slidesToShow={slidesToShow}
          heightMode="max"
          {...props}
        />
      </div>
    )
  }

  render() {
    const { slidesToShow, responsiveSlidesToShow } = this.props
    return (
      <ContainerDimensions>
        {({ width }) => {
          this.resize()
          let actualSlidesToShow =
            slidesToShow ||
            (responsiveSlidesToShow &&
              responsiveSlidesToShow[this.getWidth(width)]) ||
            RESPONSIVE_ITEMS_PER_ROW[this.getWidth(width)]
          return this.renderCarousel({
            width,
            slidesToShow: actualSlidesToShow,
          })
        }}
      </ContainerDimensions>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(CustomCarousel)
