import React from 'react'
import Grid from '@material-ui/core/Grid'

const GridContainer = (props) => {
  return <Grid container spacing={1} {...props} />
}

const MediaGrid = ({ children }) => {
  return (
    <GridContainer>
      <Grid item xs={12} md={6}>
        {React.Children.toArray(children)[0]}
      </Grid>
      <Grid item xs={12} md={6}>
        <GridContainer>
          {React.Children.toArray(children)
            .slice(1, 5)
            .map((c, i) => (
              <Grid item xs={6} md={6} key={i}>
                {c}
              </Grid>
            ))}
        </GridContainer>
      </Grid>
      {React.Children.toArray(children)
        .slice(5)
        .map((c, i) => (
          <Grid item xs={6} md={3} key={i}>
            {c}
          </Grid>
        ))}
    </GridContainer>
  )
}

export default MediaGrid
