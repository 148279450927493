import Button from '@material-ui/core/Button'
import PlayIcon from '@material-ui/icons/PlayArrow'
import React from 'react'
import { searchAndPlayResults } from '~/ducks/player'
import { open as openToast } from '~/ducks/toast'
import { useDispatch } from '~/reducers'

interface Props {
  title: string
  objectId: string
}
const PlayAllButton = ({ title, objectId, ...props }: Props) => {
  const dispatch = useDispatch()

  const notifyNoMedia = () => {
    dispatch(
      openToast({
        message: "Nous n'avons pas trouvé de média à jouer. Désolé.",
      })
    )
  }

  const handleClick = () => {
    dispatch(
      searchAndPlayResults({
        playlistTitle: title,
        objectId,
      })
    ).catch((err: any) => {
      if (err.message === 'no-media') {
        notifyNoMedia()
      } else {
        throw err
      }
    })
  }

  return (
    <Button color="primary" variant="outlined" onClick={handleClick} {...props}>
      <PlayIcon />
      Tout jouer
    </Button>
  )
}

export default PlayAllButton
