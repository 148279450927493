import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Item from '~/components/Item'
import withWidth from '~/utils/withWidth'

const styles = (theme) => ({
  root: {},
  item: {},
})

export const SIZES = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2,
}

const SearchGrid = ({
  classes,
  results,
  onPlayClick,
  width,
  sizes = SIZES,
}) => (
  <div className={classes.root}>
    <Grid container spacing={5}>
      {results &&
        results.map((node) => (
          <Grid item xs={sizes[width]} key={node.global_id}>
            <Item
              globalId={node.global_id}
              slug={node.slug}
              thumbnail={node.thumbnail}
              name={node.name}
              headline={node.description}
              authors={
                node.bands ? [...node.bands, ...node.authors] : undefined
              }
              editor={node.editor_display_name}
              className={classes.item}
              onPlayClick={() => onPlayClick(node)}
              duration={node.duration}
              startYear={node.start_year}
              endYear={node.end_year}
              location={node.birth_place}
            />
          </Grid>
        ))}
    </Grid>
  </div>
)

SearchGrid.defaultProps = {}
SearchGrid.propTypes = {
  onPlayClick: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ).isRequired,
}
export default compose(withWidth, withStyles(styles))(SearchGrid)
