import React from 'react'
import Carousel from '~/components/Carousel'
import Item from '~/components/Item'
import PropTypes from 'prop-types'

const SearchCarousel = ({ results, onPlayClick, carouselProps = {} }) => (
  <Carousel {...carouselProps}>
    {results &&
      results.map((node) => (
        <div key={node.global_id}>
          <Item
            globalId={node.global_id}
            thumbnail={node.thumbnail}
            duration={node.duration}
            slug={node.slug}
            name={node.name}
            onPlayClick={() => onPlayClick(node)}
            startYear={node.start_year}
            endYear={node.end_year}
            headline={node.description}
            authors={node.bands ? [...node.bands, ...node.authors] : undefined}
            location={node.birth_place}
          />
        </div>
      ))}
  </Carousel>
)

SearchCarousel.defaultProps = {
  slidesToShow: 3,
}
SearchCarousel.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      thumbnail: PropTypes.string,
      duration: PropTypes.number,
    })
  ).isRequired,
  slidesToShow: PropTypes.number.isRequired,
}
export default SearchCarousel
