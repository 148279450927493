import React from 'react'
import MediaGrid from '~/components/MediaGrid'
import Item from '~/components/Item'
import PropTypes from 'prop-types'

const SearchMediaGrid = ({ results, onPlayClick }) => (
  <MediaGrid>
    {results &&
      results.map((node) => (
        <Item
          key={node.global_id}
          globalId={node.global_id}
          thumbnail={node.thumbnail}
          duration={node.duration}
          slug={node.slug}
          name={node.name}
          onPlayClick={() => onPlayClick(node)}
          startYear={node.start_year}
          endYear={node.end_year}
          headline={node.description}
          authors={node.bands ? [...node.bands, ...node.authors] : undefined}
          location={node.birth_place}
        />
      ))}
  </MediaGrid>
)

SearchMediaGrid.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      thumbnail: PropTypes.string,
      duration: PropTypes.number,
    })
  ).isRequired,
}
export default SearchMediaGrid
